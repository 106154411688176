import { render, staticRenderFns } from "./DetailOptimization.vue?vue&type=template&id=b0184c58&scoped=true&"
import script from "./DetailOptimization.vue?vue&type=script&lang=js&"
export * from "./DetailOptimization.vue?vue&type=script&lang=js&"
import style0 from "./DetailOptimization.vue?vue&type=style&index=0&id=b0184c58&lang=scss&scoped=true&"
import style1 from "./DetailOptimization.vue?vue&type=style&index=1&lang=css&"
import style2 from "./DetailOptimization.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0184c58",
  null
  
)

export default component.exports